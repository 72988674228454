import React, {Component} from 'react';
import { RedocStandalone } from 'redoc';

class Redoc extends Component {

    componentDidMount() {

    }

    render() {
        return (
            <RedocStandalone
                specUrl="endpoints.yaml"
                options={{
                    nativeScrollbars: false,
                    hideDownloadButton: true,
                    noAutoAuth: true,
                    // hideHostname: true,
                    expandDefaultServerVariables: true,
                    onlyRequiredInSamples: false,
                    hideLoading: true,
                    expandResponses: '200',
                    theme: {
                        colors: { primary: { main: '#474e54' } }
                    },
                }}
            />
        );
    }
}


export default Redoc;